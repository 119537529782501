<template>
    <div class="up_content_inner up_manage_site">
        <div class="up_main_container">
            
            <div class="up_inner_container">
                
                <!-- <DataTable :value="customers" :lazy="true" :paginator="true" :rows="10" v-model:filters="filters" ref="dt" dataKey="id"
            :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)" filterDisplay="row"
            :globalFilterFields="['name','country.name', 'company', 'representative.name']" responsiveLayout="scroll"
            v-model:selection="selectedNotifications" :selectAll="selectAll" @select-all-change="onSelectAllChange" @row-select="onRowSelect" @row-unselect="onRowUnselect"> -->

                <DataTable :value="allNotificationsData.rows" responsiveLayout="scroll" :paginator="true" :rows="10"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[10,20,50]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" v-model:selection="selectedNotifications" :selectAll="selectAll" @select="onSelectAllChange" @select-all-change="onSelectAllChange" @row-select="onRowSelect" @row-unselect="onRowUnselect"  :loading="products.loading" removableSort>
                    <template #loading>
                        Loading Notifications. Please wait.
                    </template>
                    <template #header>
                        <div class="table-header">
                            
                        <div class="notification_date_tableHeader">
                            <div class="up_page_header">
                                <h2 class="up_page_title">Manage Notifications</h2>
                            </div>
                            <div class="p-d-flex p-align-center">
                                <Button  icon="pi pi-trash" class="p-button-danger p-button-raised p-button-rounded mr-2"  v-tooltip.left="'Delete All Notifications'" label="Delete All" @click="bulkDelete"/>
                                <span class="p-input-icon-left sup_search_box">
                                    <i class="pi pi-search" />
                                    <InputText  placeholder="Keyword Search" />
                                </span>
                            </div>
                        </div>
                        </div>
                    </template>
                    <template #empty>
                        No Notifications available.
                    </template>
                        <Column selectionMode="multiple" headerStyle="width: 4em" field="msg" style="text-align: center;">
                        </Column>
                    <Column field="msg" header="Notification" headerStyle="width: 70em" :sortable="true">
                        <template #body="slotProps">
                            <span>
                                {{slotProps.data.notificationText}}
                            </span>
                        </template>
                    </Column>
                    <!-- <Column field="category" header="Created on"  :sortable="true"></Column> -->
                    <Column field="postDate" header="Created on" headerStyle="width: 12rem" style="text-align: center;" :sortable="true">
                        <template #body="slotProps">
                            <span>
                                {{slotProps.data.createdAt.substring(0,10)}}
                            </span>
                        </template>
                    </Column>
                    <Column field="rating" header="Action" headerStyle="width: 6em" style="text-align: center;">
                        <template #body="slotProps">
                            <Button  icon="pi pi-trash" class="p-button-danger p-button-raised p-button-rounded"  v-tooltip.left="'Delete Notification'"  @click="deleteThis(slotProps.data.uuid)"/>

                        </template>
                    </Column>

                    <template #footer>
                        In total there are {{allNotificationsData.rows ? allNotificationsData.rows.length : 0 }} notifications.
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { onBeforeMount, reactive } from 'vue';
import { useStore } from 'vuex';
import axios from "axios";

import { apiKey } from "../../../../config/constant";
import { userPortalNotification } from '../../../../config/appUrls';
const store = useStore();
export default{
    name:'userNotificationAll',
    components: {},
    data(){
        return {
            loading: false,
            columns: null,
            products: null,
            selectedNotifications: null,
            selectAll: false,
            first: 0,
            last: 10
        }
    },
    created() {
        this.products = [
        {"id": "1000","msg": "Sed arcu non odio euismod lacinia at quis risus.","postDate":"21-08-2022"},
        {"id": "1001","msg": "Dictumst vestibulum rhoncus est pellentesque elit ullamcorper","postDate":"01-08-2022"},
        {"id": "1002","msg": "Vestibulum lorem sed risus ultricies tristique nulla.","postDate":"17-08-2022"},
        {"id": "1003","msg": "Mauris vitae ultricies leo integer malesuada nunc vel risus. Pulvinar elementum integer.","postDate":"21-08-2022"},
        {"id": "1004","msg": "Nunc lobortis mattis aliquam faucibus purus in massa. Eleifend mi in nulla posuere","postDate":"22-08-2022"},
        {"id": "1005","msg": "At tellus at urna condimentum mattis pellentesque id nibh.","postDate":"21-09-2022"},
        {"id": "1006","msg": "Sed arcu non odio euismod lacinia at quis risus.","postDate":"10-05-2022"},
        {"id": "1007","msg": "Egestas egestas fringilla phasellus faucibus.","postDate":"13-05-2022"},
        {"id": "1008","msg": "Sit amet consectetur adipiscing elit ut aliquam purus sit. Dui accumsan sit amet nulla. Morbi tincidunt augue interdum velit euismod.","postDate":"21-08-2022"},
        {"id": "1009","msg": "Sed arcu non odio euismod lacinia at quis risus.","postDate":"28-09-2022"},
        {"id": "1010","msg": "Sed vulputate mi sit amet mauris commodo quis imperdiet massa. Id venenatis a condimentum.","postDate":"30-08-2022"},
        {"id": "1011","msg": "Sed arcu non odio euismod lacinia at quis risus.","postDate":"31-06-2022"},
        {"id": "1012","msg": "Sed arcu non odio euismod lacinia at quis risus.","postDate":"29-06-2022"},
    ]
    },
    setup() {
        onBeforeMount( async() => {
            fetchNotifications();
        })
        
        //Application store call
        // const store = useStore();

        const allNotificationsData = reactive({
            rows: null,
            isLoading: false,
            total: null,
            selections: {}
        });

        const fetchNotifications = async function() {
            try {
                allNotificationsData.isLoading = true;
                const response = await axios.get( userPortalNotification+'/user', {
                    params: {
                        // search: allCustomerData.search,
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                
                if(response.data.status === 200) {
                    allNotificationsData.isLoading = false;
                    allNotificationsData.rows = response.data.data.notifications;
                    allNotificationsData.total = response.data.data.total;
                }
                allNotificationsData.isLoading = false;
            } catch(error) {
                allNotificationsData.isLoading = false;
                console.log(error);
            }
        }   

        const deleteThis = async function(uuid) {
            console.log(uuid);
            try {
                const response = await axios.delete( userPortalNotification, {
                    params: {
                        payload: uuid
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                if(response.data.status === 200) {
                    await fetchNotifications();
                } else {
                    // toast for error message
                }
            } catch(err) {
                console.log(err);
            }
        }

        return {
            allNotificationsData,
            fetchNotifications,
            deleteThis
        }
    },
    methods: {
        onSelectAllChange(event) {
            const selectAll = event.checked;
            console.log(this.selectAll)
            if (selectAll) {
                //this.selectedNotifications = this.selectedNotifications.slice(this.first, this.)
                this.customerService.getCustomers().then(data => {
                    this.selectAll = true;
                    this.selectedNotifications = data.customers;
                });
            }
            else {
                this.selectAll = false;
                this.selectedNotifications = [];
            }
            console.log(this.selectedNotifications)
        },
        onRowSelect() {
            console.log('on Row Select', this.selectedNotifications);
            this.selectAll = this.selectedNotifications.length === this.totalRecords
        },
        onRowUnselect() {
            this.selectAll = false;
        },
        bulkDelete() {
             (async()=> {
                //Application store call
                // const store = useStore();
                console.log(this.selectedNotifications);

                if(this.selectedNotifications === null || this.selectedNotifications.length === 0) {
                    console.log('nothing to delte')
                    return
                }
                let payload='';
                let i;
                for(i=0; i< this.selectedNotifications.length; i++) {
                    payload = payload + ',' + this.selectedNotifications[i].uuid;
                }
                payload = payload.substring(1, payload.length);
                console.log(payload);
                try {
                    const response = await axios.delete( userPortalNotification, {
                        params: {
                            payload: ''
                        },
                        headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        }, 
                    });
                    if(response.data.status === 200) {
                        await this.fetchNotifications();
                    } else {
                        // toast for error message
                    }
                } catch(err) {
                    console.log(err);
                }
            })()
        }
    }
}
</script>
<style scoped>
@import '../../../../assets/style/component/userportal/pages/userNotifications/userNotification.css';
</style>